/* -----------priyanshu-theme-css-start---------- */
.dark {
  background-color: #dee2e2;
}
.light {
  background-color: #ffffff;
}

/* -----------priyanshu-theme-css-end------------- */
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgba(0, 0, 0, 0) !important; */
  /* background-color: white;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Gaytri css start  */
.fullContainer {
  height: 100vh !important;
}
.formColor {
  background-color: rgb(255, 255, 255) !important;
}
.textLeft {
  text-align: left !important;
}
.heading4 {
  color: #334155;
  color: rgb(51, 65, 85);
  font-weight: 600;
}
.formcrossbtn {
  float: right !important;
  cursor: pointer !important;
}
/* ---------Priyanshu-Start------------- */
/* body {
  background-color: #f8fafc !important;
} */
.Sign-up-container {
  height: calc(100vh - 82px);
  /* background-color: #ecaeae; */
}
.forget-container {
  height: calc(100vh - 82px);
}
.Sign-up-container-inner {
  /* background-color: rgb(213, 245, 166); */
}
.formWidth {
  text-align: start;
  width: 400px;
}

.formWidth {
  width: 400px !important;
}

.tableBg,
.modelBg {
  background-color: #334155;
  color: #fff !important;
}

.tableEditbtn {
  background-color: #8a9097 !important;
}

.tableDeletebtn {
  color: #fff !important;
  border-color: #fff !important;
}
/* Gaytri css end  */
/*################################################################################# */
/* karan start css */
.login-button {
  align-items: center;
  margin: 0 !important;
  gap: 10px !important;
}
.login-nav {
  gap: 10px !important;
}
.login-admin {
  background-color: #2563eb !important;
  padding: 4px 17px !important;
  border-radius: 8px;
  color: white !important;
  height: 33px !important;
  text-decoration: none;
}
.tenpx-gap {
  min-width: 100%;
  gap: 10px;
}
.main-div {
  background-color: white !important;
  padding: 0px 21px !important;
  border-bottom: 1px solid #e2e8f0 !important;
}
.Toggle-button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.btn-resend {
  width: 50%;
  margin: 0 auto;
}

@media screen and (max-width: 991px) {
  .login-button {
    align-items: flex-start;
  }
  .login-nav {
    flex-direction: row !important;
  }
  .main-div {
    padding: 12px 0px !important;
  }
}

/* karan css end */
.Fpswd-Clogin {
  text-decoration: none;
  color: #2563eb;
}
@media (max-width: 450px) {
  .formWidth {
    width: 350px;
  }
}
@media (max-width: 380px) {
  .formWidth {
    width: 100%;
    min-width: 300px;
  }
}
/* -------P-Dashboard----- */
.admin-navbar {
  background: hsla(289, 93%, 21%, 1);

  background: linear-gradient(
    180deg,
    hsla(289, 93%, 21%, 1) 0%,
    hsla(287, 93%, 6%, 1) 15%,
    hsla(0, 0%, 0%, 1) 84%,
    hsla(289, 92%, 20%, 1) 97%
  );

  background: -moz-linear-gradient(
    180deg,
    hsla(289, 93%, 21%, 1) 0%,
    hsla(287, 93%, 6%, 1) 15%,
    hsla(0, 0%, 0%, 1) 84%,
    hsla(289, 92%, 20%, 1) 97%
  );

  background: -webkit-linear-gradient(
    180deg,
    hsla(289, 93%, 21%, 1) 0%,
    hsla(287, 93%, 6%, 1) 15%,
    hsla(0, 0%, 0%, 1) 84%,
    hsla(289, 92%, 20%, 1) 97%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#560469", endColorstr="#17011D", GradientType=1 );
}
.admin-btn-logo {
  color: white;
}
.search-bar-dash {
  width: 350px !important;
}
.search-bar-dash-inner {
  background-color: #f8f9fa !important;
}
/* .sidebar.visible { */
/* width: 0px; */
/* display: none;
} */
/* .sidebar {
  width: 21%;
  height: 100vh !important;
  background-color: #120c16;
  color: #fff; */
/* white-space: nowrap; */
/* transition: all ease-in-out 0.5s;
} */

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgba(0, 0, 0, 0) !important; */
  /* background-color: white;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Gaytri css start  */
.fullContainer {
  height: 100vh !important;
}
.formColor {
  background-color: rgb(255, 255, 255) !important;
}
.textLeft {
  text-align: left !important;
}
.heading4 {
  color: #334155;
  color: rgb(51, 65, 85);
  font-weight: 600;
}
.formselect {
  width: 335px !important;
}
.formbtn {
  float: right !important;
}

.MuiFormControl-root {
  margin-bottom: 8px !important;
  width: 100% !important;
}
.tableBg p,
.MuiTablePagination-actions button {
  color: white !important;
}
.tableBg svg {
  fill: white !important;
  /* background-color: white !important;
  background-color: #550467 !important;
  border-color: #550467 !important; */
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiDataGrid-sortIcon.css-ptiqhd-MuiSvgIcon-root,
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiDataGrid-sortIcon.css-ptiqhd-MuiSvgIcon-root {
  fill: #fff;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiDataGrid-sortIcon.css-ptiqhd-MuiSvgIcon-root
  svg {
  fill: #fff;
}
/* ---------Priyanshu-Start------------- */
/* body {
  background-color: #f8fafc !important;
} */
.Sign-up-container {
  height: 100vh;
  /* background-color: #ecaeae; */
}
.Sign-up-container-inner {
  /* background-color: rgb(213, 245, 166); */
}
.formWidth {
  text-align: start;
  width: 400px;
}
/* .globalbtnColor {
  color: #ffffff !important;
  background-color: #1d4ed8 !important;
} */

.formWidth {
  width: 400px !important;
}
/* Gaytri css end  */
/*################################################################################# */
/* karan start css */
.profileDiv {
  width: 100% !important;
}
.auto-text {
  width: 100%;
}

.login-button {
  align-items: center;
  margin: 0 !important;
  gap: 10px !important;
}
.login-nav {
  gap: 10px !important;
}
.login-admin {
  background-color: #2563eb !important;
  padding: 4px 17px !important;
  border-radius: 8px;
  color: white !important;
  height: 33px !important;
}
.main-div {
  background-color: white !important;
  padding: 21px 21px !important;
  border-bottom: 1px solid #e2e8f0 !important;
}
.Knowlege-Base:hover {
  background-color: #e7e7e7;
}
.Knowlege-Base {
  padding: 4px 0px !important;
  border-radius: 7px !important;
  color: #334155 !important;
}
@media screen and (max-width: 991px) {
  .login-button {
    align-items: flex-start;
  }
  .login-nav {
    flex-direction: row !important;
  }
}

/* karan css end */
.Fpswd-Clogin {
  text-decoration: none;
  color: #2563eb;
}
@media (max-width: 450px) {
  .formWidth {
    width: 350px;
  }
}
@media (max-width: 380px) {
  .formWidth {
    width: 100%;
    min-width: 300px;
  }
}
/* -------P-Dashboard----- */
.admin-navbar {
  background: hsla(289, 93%, 21%, 1);

  background: linear-gradient(
    180deg,
    hsla(289, 93%, 21%, 1) 0%,
    hsla(287, 93%, 6%, 1) 15%,
    hsla(0, 0%, 0%, 1) 84%,
    hsla(289, 92%, 20%, 1) 97%
  );

  background: -moz-linear-gradient(
    180deg,
    hsla(289, 93%, 21%, 1) 0%,
    hsla(287, 93%, 6%, 1) 15%,
    hsla(0, 0%, 0%, 1) 84%,
    hsla(289, 92%, 20%, 1) 97%
  );

  background: -webkit-linear-gradient(
    180deg,
    hsla(289, 93%, 21%, 1) 0%,
    hsla(287, 93%, 6%, 1) 15%,
    hsla(0, 0%, 0%, 1) 84%,
    hsla(289, 92%, 20%, 1) 97%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#560469", endColorstr="#17011D", GradientType=1 );
}
.admin-btn-logo {
  color: white;
}
.search-bar-dash {
  width: 350px !important;
}
.search-bar-dash-inner {
  background-color: #f8f9fa !important;
}
.sidebar.visible {
  width: 0px;
  /* display: none; */
}
.sidebar {
  width: 21%;
  min-height: 100vh;
  position: relative;
  /* height: 100vh !important; */
  background-color: #120c16;
  color: #fff;
  white-space: nowrap;
  overflow-x: hidden;
  transition: width 0.3s ease-in-out;
}
@media (max-width: 960px) {
  .sidebar {
    width: 25%;
  }
}
@media (max-width: 810px) {
  .sidebar {
    width: 30%;
  }
}
@media (max-width: 700px) {
  .sidebar {
    width: 35%;
  }
}
@media (max-width: 620px) {
  .sidebar {
    width: 40%;
  }
}
@media (max-width: 560px) {
  .sidebar {
    width: 45%;
  }
}
.dash-list li {
  list-style: none;
  text-align: start;
  padding: 8px 13px;
}
.dash-list {
  padding: 0px;
  margin: 0px;
}
.blank-box {
  height: 62px;
  width: 100%;
  background-color: #21072c57;
}
.text-decoration-none {
  text-decoration: none;
  color: white;
}
.text-decoration-none :hover {
  background-color: #c45bf157;
}
.text-decoration-none button {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.eye-bttn {
  position: absolute;
  top: 0;
  right: 5px;
  padding: 5px;
}
.Password-input-eye {
  position: relative;
}
/* ---------Priyanshu-End------------- */

.dash-list li {
  list-style: none;
  text-align: start;
  padding: 8px 13px;
}
.dash-list {
  padding: 0px;
  margin: 0px;
}
.blank-box {
  height: 62px;
  width: 100%;
  background-color: #21072c57;
}
.text-decoration-none {
  text-decoration: none;
  color: white;
}
.text-decoration-none :hover {
  background-color: #c45bf157;
}
/* ---------Priyanshu-End------------- */
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  background-color: #fff !important;
  padding: 3px;
}

.text-decoration-none :hover {
  background-color: #c45bf157;
}
.eye-bttn {
  position: absolute;
  top: 0;
  right: 5px;
  padding: 5px;
}
.Password-input-eye {
  position: relative;
}
.projectScreenCard {
  /* max-width: 500px !important; */
  width: 100%;
}
.projectScreenCard2 {
  /* max-width: 500px !important; */
  width: 100%;
  height: min-content !important;
}
.categoriesProject {
  max-width: 1025px !important;
  margin: 0px auto;
}
.categorieslist {
  width: 100% !important;
}
.chatboxes {
  max-width: 50%;
  min-width: 48% !important;
  max-height: 115px;
}
.projectScreenCard-outer {
  margin: 0 auto;
  max-width: 90%;
}

.setfilds {
  flex: 1;
  padding-top: 30px;
  margin-right: 30px;
}
.setfilds-btn {
  flex: 1;
  padding-top: 30px;
  margin-right: 30px;
}

.topname {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
}

.dsflex {
  flex: 1;
}
/* .form-check-input:checked {
  background-color: #0dfd35 !important;
  border-color: #0dfd35 !important;
}
.form-check-input:checked .form-check-input {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
} */
.copieddata p {
  background-color: white !important;
  color: black !important;
  padding: 5px !important;
  border-bottom-left-radius: none !important;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.gapClass {
  gap: 10px;
}

.w-90 {
  max-width: 90%;
  margin: 0 auto;
}
.svg-add-remove-icon {
  margin: 8px 5px;
}
.mr-gs-5 {
  margin-right: 5px;
}
.label-w {
  min-width: 130px;
}

.form-control:focus,
.form-select:focus {
  color: none !important;
  background-color: none !important;
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}
input.setfildsinput.form-control {
  font-size: 11px;
  font-weight: 600px;
  padding: 5px !important;
}
span.setfildsinput {
  font-size: 11px;
  font-weight: 700;
  padding: 8px !important;
}

select.setfildsinput.form-select {
  padding: 10px;
  background-position: right 2px center !important;
  font-size: 11px;
}
/* button.setfildsinput.btn.btn-primary.btn-sm {
  margin: 10px 0 10px 3px !important;
  border-radius: 2px !important;
} */

/* ..........................gaytri css...................... */
form#frameForm {
  max-width: 500px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.form-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

input.ginput,
select.ginput {
  padding: 10px;
  margin: 10px !important;
  border-radius: 5px;
  width: 100%;
}

button {
  padding: 10px 16px;
  margin: 10px 0px;
  background: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: 700;
}

select {
  border: 2px solid rgb(118, 118, 118);
}

#frameForm input {
  box-sizing: border-box;
}

.btncontainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

@media only screen and (max-width: 500px) {
  #frameForm {
    width: 232px !important;
  }
}
/* all batton background color css */
.allBtnBg {
  background: webkit-linear-gradient(
    180deg,
    #550467,
    #17011e 15%,
    #000 84%,
    #510462 97%
  ) !important;
  background: linear-gradient(
    180deg,
    #550467,
    #17011e 15%,
    #000 84%,
    #510462 97%
  ) !important;
  color: #fff !important;
  text-decoration: none !important;
  border-color: none;
}

.allToggleBtn:checked {
  background-color: #550467 !important;
  border-color: #550467 !important;
}

/* CSS */
.svg-add-remove-icon.remove {
  fill: red; /* Change the color to red */
}
.svg-add-remove-icon.add {
  fill: green; /* Change the color to red */
}

/**********Responsive CSS by Gaytri************************/
.sidebarCrossBtn {
  position: absolute;
  top: 25px;
  right: 10px;
  display: none;
}

.profileNavShowSmallScreen {
  display: none;
}

.profileHideNavSmallScreen {
  display: block;
}
/* media query for responsivnes od side bar respect to rigth side container */
@media only screen and (max-width: 800px) {
  .setfilds-btn {
    flex: 1;
    padding-top: 00px;
    margin-right: 30px;
  }

  .fieldScreenContainer {
    flex-direction: column !important;
  }
  .topname {
    flex-direction: column !important;
    align-items: flex-start;
  }
  .allfieldScreenCBody {
    overflow-y: auto;
    max-height: 100vh;
  }
}

@media only screen and (max-width: 1179px) {
  /* in app.js */
  .profileHideNavSmallScreen {
    display: none !important;
  }
  /* in sidebar.js */
  .profileNavShowSmallScreen {
    display: block;
  }
  .sidebar.visible {
    width: 50%;

    /* display: none; */
  }
  .sidebar {
    position: fixed;
    top: 0;
    width: 0px;
    height: 100%;
    transition: left 0.3s;
    z-index: 999;
    transition: width 0.5s ease-in-out;
    max-width: 300px;
  }
  .sidebarCrossBtn {
    display: block !important;
  }
}

.dashboardWrapper {
  max-width: 94%;
  margin: 0 auto;
}
